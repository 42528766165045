<template>
  <div class="biddingManager_wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      招标订阅设置
    </div>
    <div class="body__wrapper">
      <div class="item_title">
        <div class="item_titleline"></div>
        订阅范围
      </div>
      <a-form-model ref="form" :rules="rules" :model="form" v-bind="formWrapper">
        <a-form-model-item label="招标范围" prop>
          <a-input :value="'招标公告'" disabled style="width: 400px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地区范围" prop>
          <a-input :value="'全国'" disabled style="width: 400px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="日期" prop>
          <a-radio-group name="radioGroup" :default-value="'今日'">
            <a-radio value="今日"> 今日 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="行业">
          <a-radio-group v-model="form.key1" name="radioGroup">
            <a-radio value="电力"> 电力 </a-radio>
            <a-radio value="化工"> 化工 </a-radio>
            <a-radio value="冶金"> 冶金 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="专业">
          <a-radio-group name="radioGroup" v-model="form.key2">
            <a-radio value="检修"> 检修 </a-radio>
            <a-radio value="运行"> 运行 </a-radio>
            <a-radio value="维护"> 维护 </a-radio>
            <a-radio value="安装"> 安装 </a-radio>
            <a-radio value="改造"> 改造 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="接收邮箱" prop="email">
          <a-input v-model="form.email" placeholder="请输入邮箱" style="width: 800px"></a-input>
          <div>
            <span
              class="tips"
            >您订阅的招标邮件会在每天的9时和14时分别更新发送，请注意邮件箱提醒并及时查收。如当天邮箱内未接收到订阅招标邮件则全国也未产生新的招标信息，不予推送。</span
            >
          </div>
        </a-form-model-item>
      </a-form-model>
      <div class="box-btn" v-perms="'save'">
        <a-button class="" type="" @click="handleReset">重 置</a-button>
        <a-button class="" type="primary" @click="handleSubmit" :loading="loading">保 存</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { add, update, getById } from '@/api/biddingManager'
import { mapState } from 'vuex'

function basicForm () {
  return {
    fid: '133',
    region: '全国',
    timeslot: 'd',
    key1: '电力',
    key2: '检修',
    email: ''
  }
}

export default {
  name: 'Index',
  data () {
    return {
      loading: false,
      form: basicForm(),
      rules: {
        email: [
          { required: true, message: '请输入接收邮箱', trigger: 'blur' },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确邮箱地址'
          }
        ]
      },
      formWrapper: {
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      }
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.info
    })
  },
  methods: {
    init () {
      getById(this.userInfo.enterpriseInfoDto?.enterpriseId).then((res) => {
        console.log(res.data)
        if (res.data) {
          this.form = res.data
        }
      })
    },
    handleReset () {
      this.form = basicForm()
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const fun = this.form.id ? update : add
          this.loading = true
          fun({
            ...this.form,
            enterpriseId: this.userInfo.enterpriseInfoDto?.enterpriseId
          })
            .then(() => {
              this.$message.success('招标订阅设置成功')
              this.init()
            })
            .finally(() => (this.loading = false))
        }
      })
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
@import url('./index');
.biddingManager_wrapper {
  height: 100%;
  .body__wrapper {
    height: calc(100% - 45px);
    padding: 24px;
    background-color: #ffffff;
  }
  .tips {
    display: inline-block;
    background: rgba(244, 161, 92, 0.1);
    color: #f4a15c;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
  }
  .box-btn {
    text-align: center;
    margin-top: 40px;
  }
}
</style>
